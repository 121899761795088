<template>
  <div class="graph">
    <div style="position: absolute; top: 20px; left:20px; z-index: 2;">
      <el-radio-group v-model="modelFreqText">
        <el-radio-button label="周度预测模型"></el-radio-button>
        <el-radio-button label="月度预测模型"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="back_box" ref="back_box" @wheel="handleWeel">
      <div
        class="drag_box"
        ref="drag_box"
        draggable="true"
        @dragstart="dragstart"
        @dragend="dragend"
        
        :style="`left:${elLeft}px;top:${elTop}px;-webkit-transform: scale(${zoom} )`"
      >
        <ProductGraph :modelFreq="modelFreq"></ProductGraph>
      </div>
    </div>
    <div class="scale">
      <el-slider v-model="zoom" :step="zoomStep" :min="minZoom" :max="maxZoom" :show-tooltip="false" vertical height="100px" :marks="{0.5:'缩小',1:'-',2:'放大'}"></el-slider>
    </div>
  </div>
</template>

<script>
import ProductGraph from "@/components/productGraph/ProductGraph";
export default {
  name: "productGraph",
  components:{
    ProductGraph
  },
  props: {
    
  },
  data() {
    return {
      startclientX: 0, // 元素拖拽前距离浏览器的X轴位置
      startclientY: 0, //元素拖拽前距离浏览器的Y轴位置
      startX: 0, // 元素拖拽前左偏移
      startY: 0, //元素拖拽前右偏移
      elLeft: 0, // 元素的左偏移量
      elTop: 0, // 元素的右偏移量
      zoom: 1, // 缩放比例
      minZoom:0.5,
      maxZoom:2,
      zoomStep:0.05,
      modelFreqText:'周度预测模型',
    };
  },
  computed:{
    modelFreq(){
      return this.modelFreqText=="周度预测模型"?'week':'month'
    }
  },
  methods: {
    // 页面初始化
    initBodySize() {
      let initWidth = this.$refs.back_box.clientWidth, // 拿到父元素宽 = this.$refs.back_box.clientWidth, // 拿到父元素宽
          initHeight = this.$refs.back_box.clientHeight, // 拿到父元素宽
          elWidth = this.$refs.drag_box.clientWidth, // 拿到拖拽元素宽度
          elHeight = this.$refs.drag_box.clientHeight; // 拿到拖拽元素高度

      this.zoom = Math.min(initWidth/elWidth,initHeight/elHeight,this.maxZoom); // 计算元素缩放比例
      this.elLeft=(elWidth*this.zoom-elWidth)/2+(initWidth-elWidth*this.zoom)/2
      this.elTop=(elHeight*this.zoom-elHeight)/2+(initHeight-elHeight*this.zoom)/2
      
    },
    // 拖拽开始事件
    dragstart(e) {
      this.startclientX = e.clientX; // 记录拖拽元素初始位置
      this.startclientY = e.clientY;
      this.startX=this.elLeft
      this.startY=this.elTop
    },
    dragOver(e) {
      let x = e.clientX - this.startclientX; // 计算偏移量
      let y = e.clientY - this.startclientY;
      this.elLeft=this.startX+x;
      this.elTop=this.startY+y;
    },
    // 拖拽完成事件
    dragend(e) {
      let x = e.clientX - this.startclientX; // 计算偏移量
      let y = e.clientY - this.startclientY;
      this.elLeft += x; // 实现拖拽元素随偏移量移动
      this.elTop += y;
    },

    // 滚轮放大缩小事件
    handleWeel(e) {
      if (e.wheelDelta < 0) {
        this.zoom -= this.zoomStep;
      } else {
        this.zoom += this.zoomStep;
      }
      if (this.zoom >= this.maxZoom) {
        this.zoom = this.maxZoom;
        return;
      }
      if (this.zoom <= this.minZoom) {
        this.zoom = this.minZoom;
        return;
      }
    },
  },
  mounted() {
    // console.log(this.$el);
    // this.initBodySize();
  },
};
</script>

<style scoped>
.back_box {
  position:absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* height: calc(100vh - 135px); */
  overflow: hidden;
}

.drag_box {
  /* width: 500px;
  height: 500px; */
  /* background: skyblue; */
  position: absolute;
  user-select: none; /* 不可选中,为了拖拽时不让文字高亮 */
  cursor:move;
}
.scale{
  position: absolute;
  right: 30px;
  top: 50%;
  background: rgba(80,80,80,0.3);
  padding:25px 20px 25px 10px;
  border-radius: 5px;
}
</style>
