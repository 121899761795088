<template>
  <div class="product-graph">

      <div class="product-item" style="top: 200px; left:0">油田/气田气</div>
      <div class="line" color="blue" style="width: 340px; top: 215px; left: 120px; z-index: 2;">
        <div class="line" color="blue" style="height: 50px; top: 0px; right: 0;">
          <div class="line" color="blue" style="width: 20px; bottom: 0px; left: 0;"><span class="arrow" direction="right" color="blue" style="right: -7px; top: -6px;"></span></div>
        </div>
      </div>
      <div class="line" style="height: 150px; top: 115px; left: 140px;">
        <div class="line" style="width: 20px; top: 0px; left: 0px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
        <div class="line" style="width: 20px; top: 50px; left: 0px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
        <div class="line" style="width: 20px; top: 150px; left: 0px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
        <div class="cross" direction="vertical" style="left:-4px; top: 96px;"></div>
      </div>
      <div class="product-item" style="top: 100px; left: 160px;">丁烷</div>
      <div class="line" style="width: 500px; top: 115px; left: 280px;">
        <div class="line" style="height: 350px; top: 0px; right: 0px;">
          <div class="line" style="width:180px; bottom: 0px; left: 0px;">
            <div class="cross" direction="horizontal" style="left:78px; top: -4px;"></div>
            <span class="arrow" direction="right" style="right: -7px; top: -6px;"></span>
          </div>
        </div>
      </div>
      <div class="product-item" style="top: 150px; left: 160px;">乙烷</div>
      <div class="product-item" style="top: 250px; left: 160px;">甲烷</div>

      <div class="product-item" style="top: 400px; left:0">原油</div>
      <div class="line" style="height: 100px; top: 315px; left: 140px;">
        <div class="line" style="width: 20px; top: 0px; left: 0px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
      </div>
      <div class="line" color="blue" style="width: 40px; top: 415px; left: 120px;"><span class="arrow" direction="right" color="blue" style="right: -7px; top: -6px;"></span></div>
      <div class="product-item" style="top: 300px; left: 160px;">油品</div>
      <div class="product-item" style="top: 400px; left: 160px;">蜡油</div>
      <div class="line" color="blue" style="width: 50px; top: 415px; left: 280px;"><span class="arrow" direction="right" color="blue" style="right: -7px; top: -6px;"></span></div>
      <div class="tech-item" style="top: 405px; left: 330px;">催化裂化</div>
      <div class="line" color="blue" style="height: 25px; top: 380px; left: 380px;"><span class="arrow" direction="top" color="blue" style="left: -6px; top: -7px;"></span></div>
      <div class="product-item" style="top: 350px; left: 320px;">丙烯原料油</div>
      <div class="line" color="blue" style="height: 25px; top: 325px; left: 380px;"><span class="arrow" direction="top" color="blue" style="left: -6px; top: -7px;"></span></div>
      <div class="tech-item" style="top: 305px; left: 330px;">气分装置</div>
      <div class="line" color="blue" style="width: 30px; top: 315px; left: 430px;">
        <div class="line" color="blue" style="height: 50px; bottom: 2px; right: 0;"></div>
        <div class="line" style="height: 188px; top: 2px; right: 0;">
          <div class="line" style="width: 20px; bottom: 40px; left: 0;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
          <div class="line" style="width: 180px; bottom: 0px; left: 0;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
        </div>
      </div>
      
      <div class="product-item" style="top: 550px; left:0px">石脑油</div>
      <div class="line" style="width: 210px; top: 565px; left: 120px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
      <div class="tech-item" style="top: 555px; left: 330px;">蒸汽裂解</div>
      <div class="line" style="width:30px; top: 565px; left: 430px;">
        <div class="line" style="height:50px; bottom: 0px; right: 0px;">
          <div class="line" style="width:180px; top: -2px; left: 0px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
        </div>
      </div>
      <div class="product-item" style="top: 600px; left: 0px;">煤炭</div>
      <div class="line" style="width: 40px; top: 615px; left: 120px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
      <div class="product-item" style="top: 600px; left: 160px;">甲醇</div>
      <div class="line" style="height: 15px; width: 0px; top: 585px; left: 220px; background: none; border-left-style:dashed; border-left-width:2px;">
        <div class="line" style="width: 60px; height:0px; top: 0px; left: 0px; background: none; border-bottom-style:dashed; border-bottom-width:2px;"></div>
      </div>
      <div class="line" style="width: 50px; top: 615px; left: 280px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
      <div class="tech-item" style="top: 605px; left: 330px;">MTO</div>
      <div class="line" style="width:40px; top: 615px; left: 430px;">
        <div class="line" style="height:90px; bottom: 0px; right: 0px;">
          <div class="line" style="width:170px; top: 0px; left: 0px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
        </div>
      </div>

      <div class="product-item" style="top: 150px; left:480px">燃料</div>
      <div class="line" style="height: 70px; top: 180px; left: 540px;"><span class="arrow" direction="top" style="left: -6px; top: -7px;"></span></div>

      <!-- <div class="text-item" style="top:205px; left: 480px;">
        <p>山东市场丙烷日度市场价</p>
        <p><span class="color-sub">2023-08-24：</span>5,539元/吨，<span class="color-green">↓1.56%</span></p>
      </div>
      <div class="product-item" color="green" style="top: 250px; left:480px">
        <el-popover
          placement="top"
          trigger="hover">
          <div class="line-height-large">
            <div class="font-size-20 margin-bottom-10"><span class="color-green">丙烷</span></div>
            <div>山东市场丙烷日度市场价</div>
            <div><span class="color-sub">2023-08-24：</span><span>7125元/吨</span>，<span class="color-green">↓1.56%</span></div>
            <div>滨化集团采购价</div>
            <div><span class="color-sub">2023-08-24：</span><span>7125元/吨</span>，<span class="color-green">↓1.56%</span></div>
            <div class="margin-top-10">08.25-08.31价格预测</div>
            <div><span class="color-sub">模型自估预测：</span><span>7125元/吨</span></div>
            <div><span class="color-sub">卓创赋值预测：</span><span>7125元/吨</span></div>
            <div><span class="color-sub">滨化赋值预测：</span><span>7125元/吨</span></div>
          </div>
          <div slot="reference" style="cursor: pointer;">丙烷</div>
        </el-popover>
      </div> -->
      <ProductItem name="丙烷" :position="{top:250,left:480}" :prodData="info.find(v=>{return v.productId==12513})" @click="gotoDetail"></ProductItem>
      
      <div class="line" style="height: 75px; top: 280px; left: 540px;"><span class="arrow" direction="bottom" style="left: -6px; bottom: -7px;"></span></div>
      <div class="line" color="blue" style="width: 100px; top: 265px; left: 600px;">
        <div class="line" color="blue" style="height: 40px; top: 0px; right: 0px;"><span class="arrow" color="blue" direction="bottom" style="left: -6px; bottom: -7px;"></span></div>  
      </div>
      <div class="tech-item" style="top: 355px; left: 490px;">丙烷裂解</div>
      <div class="line" style="height: 25px; top: 375px; left: 540px;"><span class="arrow" direction="bottom" style="left: -6px; bottom: -7px;"></span></div>
      <div class="line" style="width: 80px; top: 365px; left: 590px;">
        <div class="line" style="height: 135px; top: 0px; right: 0px;"><span class="arrow" direction="bottom" style="left: -6px; bottom: -7px;"></span></div>  
      </div>
      <div class="tech-item" style="top: 305px; left: 650px;">丙烷脱氢</div>
      <div class="line" color="blue" style="height: 175px; top: 325px; left: 700px;"><span class="arrow" color="blue" direction="bottom" style="left: -6px; bottom: -7px;"></span></div>
      <div class="product-item" style="top: 400px; left:480px">乙烯</div>
      <div class="product-item" style="top: 450px; left:480px">醚前碳四</div>

      <ProductItem name="丙烯" :position="{top:500,left:640}" :prodData="info.find(v=>{return v.productId==12605})" @click="gotoDetail"></ProductItem>
     
      <div class="line" color="blue" style="width: 210px; top: 515px; left: 760px;">
        <div class="line" style="height: 305px; bottom: 0px; left: 100px;">
          <div class="line" style="width: 90px; top: 0px; left: 0px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>  
        </div> 
        <div class="line" color="blue" style="height: 40px; top: 0px; left: 100px;"><span class="arrow" color="blue" direction="bottom" style="left: -6px; bottom: -7px;"></span></div> 
        <span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span>
      </div>

      <div class="tech-item" style="top: 505px; left: 970px;">共氧法</div>
      <div class="line" color="blue" style="width: 210px; top: 515px; left: 1070px;">
        <div class="line" color="blue" style="height: 100px; bottom: 0px; left: 100px;">
          <div class="line" color="blue" style="width: 110px; top: 0px; left: 0px;"><span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span></div>  
        </div> 
        <span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span>
      </div>
      <div class="product-item" style="top: 400px; left: 1280px;">TBA</div>
      <div class="line" color="blue" style="width: 100px; top: 415px; left: 1400px;">
        <div class="line" color="blue" style="height: 200px; bottom: 0px; right: 0px;">
          <div class="line" color="blue" style="width: 100px; top: 0px; left: 0px;">
            <span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span>
          </div> 
        </div>
      </div>

      <div class="line" style="width: 100px; top: 315px; left: 1180px; background: none; height:0px; border-bottom-style:dashed; border-bottom-width:2px;">
          <span class="arrow" direction="right" style="right: -7px; top: -6px;"></span>
      </div>
      <div class="product-item" style="top: 300px; left: 1280px;">甲醇</div>
      <div class="line" color="blue" style="width: 100px; top: 315px; left: 1400px;">
            <span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span>
      </div>

      <ProductItem name="MTBE" :position="{top:200,left:1600}" :prodData="info.find(v=>{return v.productId==12512})" @click="gotoDetail"></ProductItem>
      <div class="line" color="blue" style="width: 190px; top: 215px; left: 1720px;"><span class="arrow" direction="right" color="blue" style="right: -7px; top: -6px;"></span></div>
      <div class="line" style="height: 80px; top: 150px; left: 1910px;"></div>
      <div class="product-item" style="top: 150px; left: 1920px;">调油用MTBE</div>
      <div class="product-item" style="top: 200px; left: 1920px;">化工用MTBE</div>

      <div class="tech-item" style="top: 555px; left: 810px;">氯醇法</div>
      <div class="line" color="blue" style="width: 430px; top: 565px; left: 910px;">
        <div class="line" color="blue" style="height: 35px; bottom: 0px; right: 0px;"><span class="arrow" color="blue" direction="top" style="left: -6px; top: -7px;"></span></div>
        <div style="position:absolute; height: 35px; top: 0px; right: 0px; width: 0px; border-right: #5896e9 dashed 2px;"><span class="arrow" color="blue" direction="bottom" style="left: -6px; bottom: -7px;"></span></div>
      </div>

      
      <div class="line" style="height: 430px; top: 0px; left: 950px;"></div>
      <div class="product-item" style="top: 0px; left: 960px;">PP粉料</div>
      <div class="product-item" style="top: 50px; left: 960px;">pp粒料</div>
      <div class="product-item" style="top: 100px; left: 960px;">正丁醇</div>
      <div class="product-item" style="top: 150px; left: 960px;">辛烷</div>
      <div class="product-item" style="top: 200px; left: 960px;">丙烯腈</div>
      <div class="product-item" style="top: 250px; left: 960px;">丙烯酸</div>
      <div class="product-item" style="top: 300px; left: 960px;">丙酮</div>
      <div class="product-item" style="top: 350px; left: 960px;">ECH</div>
      <div class="product-item" style="top: 400px; left: 960px;">异丙醇</div>

      <div class="product-item" style="top: 450px; left: 960px;">异丁烷</div>
      <div class="line" color="blue" style="height: 25px; top: 480px; left: 1020px;"><span class="arrow" color="blue" direction="bottom" style="left: -6px; bottom: -7px;"></span></div> 
      
      <ProductItem name="环氧丙烷" :position="{top:500,left:1280}" :prodData="info.find(v=>{return v.productId==12602})" @click="gotoDetail"></ProductItem>

      <div class="line" color="blue" style="width: 200px; top: 515px; left: 1400px;"><span class="arrow" direction="right" color="blue" style="right: -7px; top: -6px;"></span></div>
      <div class="line" style="height: 125px; top: 390px; left: 1570px;">
        <div class="line" style="width: 20px; top: 0px; left: 0px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
      </div>
      <div class="line" style="height: 180px; top: 300px; left: 1590px;"></div>
      <div class="product-item" style="top: 300px; left: 1600px;">丙二醇</div>
      <div class="product-item" style="top: 350px; left: 1600px;">碳酸二甲酯</div>
      <div class="product-item" style="top: 400px; left: 1600px;">醇醚</div>
      <div class="product-item" style="top: 450px; left: 1600px;">其他</div>
      <div class="product-item" style="top: 500px; left: 1600px;">聚醚多元醇</div>
      
      <div class="line" color="blue" style="width: 190px; top: 515px; left: 1720px;"><span class="arrow" direction="right" color="blue" style="right: -7px; top: -6px;"></span></div>
      <div class="line" style="height: 230px; top: 300px; left: 1910px;"></div>
      <div class="product-item" style="top: 300px; left: 1920px;">POP聚醚</div>
      <div class="product-item" style="top: 350px; left: 1920px;">高回弹聚醚</div>
      <div class="product-item" style="top: 400px; left: 1920px;">弹性体聚醚</div>
      <div class="product-item" style="top: 450px; left: 1920px;">硬泡聚醚</div>
      <div class="product-item" style="top: 500px; left: 1920px;">软泡聚醚</div>

      <div class="product-item" style="top: 600px; left: 1280px;">二氯丙烷</div>
      <div class="line" color="blue" style="width: 50px; top: 615px; left: 1400px;"><span class="arrow" direction="right" color="blue" style="right: -7px; top: -6px;"></span></div>
      <div class="tech-item" style="top: 605px; left: 1450px;">滨化工艺</div>
      <div class="line" color="blue" style="width: 20px; top: 615px; left: 1550px;">
        <div class="line" color="blue" style="height: 50px; top: 0px; right: 0px;">
          <div class="line" color="blue" style="width: 30px; bottom: 0px; left: 0px;"><span class="arrow" direction="right" color="blue" style="right: -7px; top: -6px;"></span></div>
        </div>
      </div>
      <div class="product-item" style="top: 700px; left: 1280px;">四氯化碳</div>
      <div class="line" style="width: 50px; top: 715px; left: 1400px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
      <div class="tech-item" style="top: 705px; left: 1450px;">其他工艺</div>
      <div class="line" style="width: 20px; top: 715px; left: 1550px;">
        <div class="line" style="height: 50px; bottom: 2px; right: 0px;"></div>
      </div>
      
      <ProductItem name="四氯乙烯" :position="{top:650,left:1600}" :prodData="info.find(v=>{return v.productId==12993})" @click="gotoDetail"></ProductItem>
      
      <div class="line" style="width: 190px; top: 665px; left: 1720px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
      <div class="line" style="height: 130px; top: 600px; left: 1910px;"></div>
      <div class="product-item" style="top: 600px; left: 1920px;">R125</div>
      <div class="product-item" style="top: 650px; left: 1920px;">R113a</div>
      <div class="product-item" style="top: 700px; left: 1920px;">其他（干洗、金属脱脂脱氢8%）</div>

      <div class="product-item" style="top: 700px; left: 0;">原盐</div>
      <div class="line" color="blue" style="height: 225px; top: 730px; left: 60px;"><span class="arrow" direction="bottom" color="blue" style="left: -6px; bottom: -7px;"></span></div>
      <div class="product-item" style="top: 700px; left: 320px;">电石</div>
      <div class="line" color="blue" style="width: 40px; top: 715px; left: 440px;"><span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span></div>
      <div class="product-item" style="top: 700px; left: 480px;">三氯乙烯</div>

      <div class="tech-item" style="top: 955px; left: 10px;">离子膜电解</div>
      <div class="line" color="blue" style="width: 30px; top: 965px; left: 110px;"></div>
      <div class="line" color="blue" style="height: 350px; top: 765px; left: 140px;">
        <div class="line" color="blue" style="width: 20px; top: 0px; left: 0px;"><span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span></div>
        <div class="line" color="blue" style="width: 20px; top: 100px; left: 0px;"><span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span></div>
        <div class="line" color="blue" style="width: 20px; bottom: 0; left: 0px;"><span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span></div>
      </div>
      <div class="product-item" style="top: 750px; left: 160px;">氯气</div>
      <div class="line" color="blue" style="height: 100px; top: 650px; left: 220px;">
        <div class="line" color="blue" style="width: 640px; top: 0; left: 0;">
          <div class="line" color="blue" style="height: 75px; bottom: 0; right: 0;"><span class="arrow" color="blue" direction="top" style="right: -6px; top: -7px;"></span></div>
          <div class="cross" color="blue" direction="horizontal" style="left:476px; top: -4px;"></div>
        </div>
        <div class="line" color="blue" style="width: 1120px; top: 10px; left: 0;">
          <div class="line" color="blue" style="height: 30px; bottom: 0; right: 0;"><span class="arrow" color="blue" direction="top" style="right: -6px; top: -7px;"></span></div>
          <div class="cross" color="blue" direction="horizontal" style="left:476px; top: -4px;"></div>
        </div>
        <div class="line" color="blue" style="width: 1120px; top: 20px; left: 0;">
          <div class="line" color="blue" style="height: 30px; top: 0; right: 0;"><span class="arrow" color="blue" direction="bottom" style="right: -6px; bottom: -7px;"></span></div>
          <div class="cross" color="blue" direction="horizontal" style="left:476px; top: -4px;"></div>
        </div>
        <div class="line" color="blue" style="width: 320px; top: 30px; left: 0;">
          <div class="line" color="blue" style="height: 20px; top: 0; right: 0;"><span class="arrow" color="blue" direction="bottom" style="right: -6px; bottom: -7px;"></span></div>
        </div>
      </div>
      <div class="line" color="blue" style="width: 360px; top: 765px; left: 280px;"><span class="arrow" color="blue" direction="right" style="right: -7px; bottom: -6px;"></span></div>
      <div class="line" color="blue" style="height: 70px; top: 780px; left: 220px;">
        <div class="line" color="blue" style="width: 420px; top: 35px; left: 0;"><span class="arrow" color="blue" direction="right" style="right: -7px; bottom: -6px;"></span></div>
      </div>

      <div class="product-item" style="top: 750px; left: 640px;">氯丙烯</div>
      <div class="line" color="blue" style="height: 220px; top: 530px; left: 700px;"><span class="arrow" color="blue" direction="bottom" style="right: -6px; bottom: -7px;"></span></div> 
      <div class="line" style="width: 200px; top: 765px; left: 760px;"><span class="arrow" direction="right" style="right: -7px; bottom: -6px;"></span></div> 
      <div class="tech-item" style="top: 755px; left: 960px; width:100px;">丙稀法/双氧水法</div>
      <div class="line" style="width: 260px; top: 765px; left: 1080px;">
        <div class="line" style="height: 35px; top: 0; right: 0;"><span class="arrow" direction="bottom" style="right: -7px; bottom: -6px;"></span></div> 
      </div> 
     
      <ProductItem name="环氧氯丙烷" :position="{top:800,left:1280}" :prodData="info.find(v=>{return v.productId==12636})" @click="gotoDetail"></ProductItem>
      
      <div class="line" style="width: 200px; top: 815px; left: 1400px;">
        <span class="arrow" direction="right" style="right: -7px; bottom: -6px;"></span>
        <div class="line" style="height: 50px; top: 0; right: 20px;">
          <div class="line" style="width: 20px; bottom: 0; left: 0;"><span class="arrow" direction="right" style="right: -7px; bottom: -6px;"></span></div> 
        </div>
      </div> 
      <div class="product-item" style="top: 800px; left: 1600px;">环氧树脂</div>
      <div class="product-item" style="top: 850px; left: 1600px;">TGIC及其他</div>

      <div class="line" style="width: 30px; top: 815px; left: 1720px;"><span class="arrow" direction="right" style="right: -7px; top: -6px;"></span></div>
      <div class="line" style="height: 180px; top: 800px; left: 1750px;"></div>
      <div class="product-item" style="top: 800px; left: 1760px;">涂料</div>
      <div class="product-item" style="top: 850px; left: 1760px;">电子电气</div>
      <div class="product-item" style="top: 900px; left: 1760px;">复合材料</div>
      <div class="product-item" style="top: 950px; left: 1760px;">胶粘剂等</div>
      
      <div class="product-item" style="top: 800px; left: 640px;">氯化氢</div>
      <div class="line" color="blue" style="width: 260px; top: 815px; left: 760px;">
        <div class="line" color="blue" style="height: 150px; top: 0; right: 0;"></div>
      </div>

      <div class="product-item" style="top: 850px; left: 160px;">氢气</div>
      <div class="line" color="blue" style="width: 360px; top: 865px; left: 280px;"><span class="arrow" color="blue" direction="right" style="right: -7px; bottom: -6px;"></span></div>
      <div class="product-item" style="top: 850px; left: 640px;">双氧水</div>

      <div class="product-item" style="top: 950px; left: 160px;">棕榈油</div>
      <div class="line" style="width: 40px; top: 965px; left: 280px;"><span class="arrow" direction="right" style="right: -7px; bottom: -6px;"></span></div>
      <div class="product-item" style="top: 950px; left: 320px;">生物柴油</div>
      <div class="line" style="width: 40px; top: 965px; left: 440px;"><span class="arrow" direction="right" style="right: -7px; bottom: -6px;"></span></div>
      <div class="product-item" style="top: 950px; left: 480px;">粗甘油</div>
      <div class="line" color="blue" style="width: 40px; top: 965px; left: 600px;"><span class="arrow" color="blue" direction="right" style="right: -7px; bottom: -6px;"></span></div>
      
      <ProductItem name="甘油" :position="{top:950,left:640}" :prodData="info.find(v=>{return v.productId==12581})" @click="gotoDetail"></ProductItem>

      <div class="line" color="blue" style="width: 520px; top: 965px; left: 760px;"><span class="arrow" color="blue" direction="right" style="right: -7px; bottom: -6px;"></span></div>
      <div class="product-item" style="top: 950px; left: 1280px;">二氯丙醇</div>
      <div class="line" color="blue" style="height: 75px; top: 875px; left: 1340px;"><span class="arrow" color="blue" direction="top" style="right: -6px; top: -7px;"></span></div>
      <div class="tech-item" style="top: 855px; left: 1290px;">甘油法</div>
      <div class="line" color="blue" style="height: 25px; top: 830px; left: 1340px;"><span class="arrow" color="blue" direction="top" style="right: -6px; top: -7px;"></span></div>

      <ProductItem name="液碱" :position="{top:1100,left:160}" :prodData="info.find(v=>{return v.productId==12356})" @click="gotoDetail"></ProductItem>

      <div class="line" style="height: 85px; top: 1015px; left: 220px;">
        <div class="line" style="width: 730px; top: 0; left: 0;"><span class="arrow" direction="right" style="right: -7px; bottom: -6px;"></span></div>
      </div>
      <div class="line" color="blue" style="width: 210px; top: 1115px; left: 280px;"><span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span></div>
      <div class="tech-item" style="top: 1105px; left: 490px;">加热浓缩</div>
      <div class="line" color="blue" style="width: 50px; top: 1115px; left: 590px;"><span class="arrow" color="blue" direction="right" style="right: -7px; top: -6px;"></span></div>
      
      <ProductItem name="片碱" :position="{top:1100,left:640}" :prodData="info.find(v=>{return v.productId==12354})" @click="gotoDetail"></ProductItem>

      <div class="line" style="width: 100px; top: 1115px; left: 760px;">
        <div class="line" style="height: 100px; bottom: 0; right: 0;"></div>
      </div>
      <div class="line" style="height: 280px; top: 1000px; left: 950px;"></div>
      <div class="product-item" style="top: 1000px; left: 960px;">氧化铝</div>
      <div class="product-item" style="top: 1050px; left: 960px;">造纸</div>
      <div class="product-item" style="top: 1100px; left: 960px;">化纤</div>
      <div class="product-item" style="top: 1150px; left: 960px;">化工</div>
      <div class="product-item" style="top: 1200px; left: 960px;">新能源</div>
      <div class="product-item" style="top: 1250px; left: 960px;">其他（金属、冶炼、轻工、陶瓷等）</div>

      <ProductItem name="动力煤" :position="{top:1200,left:0}" :prodData="info.find(v=>{return v.productId==12713})" @click="gotoDetail"></ProductItem>

      <div class="line" color="blue" style="height: 225px; top: 975px; left: 60px;"><span class="arrow" color="blue" direction="top" style="right: -6px; top: -7px;"></span></div>
      <div class="line" color="blue" style="width: 420px; top: 1215px; left: 120px;">
        <div class="line" color="blue" style="height: 90px; bottom: 0; right: 0;"><span class="arrow" color="blue" direction="top" style="right: -6px; top: -7px;"></span></div>
      </div>

  </div>
</template>

<script>
import ProductItem from "@/components/productGraph/ProductItem";
import crypto from "@/common/crypto";
export default {
  name: 'ProductGraph',
  components:{
    ProductItem
  },
  props:{
    modelFreq:{
      type:String,
      default:'week'
    }
  },
  data() {
    return {
      loading:false,
      info:[]
    }
  },
  computed:{

  },

  methods:{
    //获取数据
    getData(){
      this.loading=true
      let request=this.$instance.get('/index/getRelationGraph',{
          params: {
            ...this.$store.state.basicParams,
            modelFreq:this.modelFreq,
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.info=res.data.info
        }else{
          this.info={}
        }
      })
      request.catch(()=>{
        this.loading=false
        this.info={}
      })
      return request
    },
    gotoDetail(item){
      if(!item){
        return false
      }
      let q=crypto.encrypt({targetId:item.targetId,productId:item.productId,productName:item.productName}),
          path=this.modelFreq=="week"?"/forecast/weekModel":"/forecast/monthModel"
      this.$router.push({
        path: path,
        query:{q}
      })
    }
  },
  created(){
    this.getData()
  },
  mounted(){
    
  },
  destroyed(){
   
  },
  watch: {
    modelFreq(){
      this.getData()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  @import './productGraph.scss';
</style>
