<template>
  <div class="product-graph-item" style="margin:0;">

    <div class="text-item" :style="{'top':(position.top-45)+'px','left': position.left+'px'}" v-if="((prodData||{}).sciInfo||{}).dataName">
      <p>{{(prodData.sciInfo||{}).dataName}}</p>
      <p><span class="color-sub">{{(prodData.sciInfo||{}).daterange}}：</span>{{(prodData.sciInfo||{}).AvgValue | numFormat(2,'-')}}{{prodData.unit}}，<span :class="textColor((prodData.sciInfo||{}).ChangePercent)">{{arrow((prodData.sciInfo||{}).ChangePercent)}}{{(prodData.sciInfo||{}).ChangePercent | numFormat(2,'-')}}%</span></p>
    </div>
    <div class="product-item" :color="itemColor(((prodData||{}).sciInfo||{}).ChangePercent)"  :style="{'top':position.top+'px','left': position.left+'px'}">
      <el-popover
        placement="top"
        trigger="hover">
        <div class="line-height-large" v-if="prodData">
          <div class="font-size-20 margin-bottom-10"><span :class="textColor((prodData.sciInfo||{}).ChangePercent)">{{prodData?prodData.productName:name}}</span></div>
          <div>{{(prodData.sciInfo||{}).dataName}}</div>
          <div><span class="color-sub">{{(prodData.sciInfo||{}).daterange}}：</span><span>{{(prodData.sciInfo||{}).AvgValue | numFormat(2,'-')}}{{prodData.unit}}</span>，<span :class="textColor((prodData.sciInfo||{}).ChangePercent)">{{arrow((prodData.sciInfo||{}).ChangePercent)}}{{(prodData.sciInfo||{}).ChangePercent | numFormat(2,'-')}}%</span></div>
          <div>滨化价格</div>
          <div><span class="color-sub">{{(prodData.bfInfo||{}).daterange}}：</span><span>{{(prodData.bfInfo||{}).AvgValue | numFormat(2,'-')}}{{prodData.unit}}</span>，<span :class="textColor((prodData.bfInfo||{}).ChangePercent)">{{arrow((prodData.bfInfo||{}).ChangePercent)}}{{(prodData.bfInfo||{}).ChangePercent | numFormat(2,'-')}}%</span></div>
          <div class="margin-top-10">{{prodData.dateCycle}}价格预测</div>
          <div><span class="color-sub">模型自估预测：</span><span>{{(prodData.predictInfo||{}).ai | numFormat(2,'-')}}{{prodData.unit}}</span></div>
          <div><span class="color-sub">卓创赋值预测：</span><span>{{(prodData.predictInfo||{}).sci | numFormat(2,'-')}}{{prodData.unit}}</span></div>
          <div><span class="color-sub">滨化赋值预测：</span><span>{{(prodData.predictInfo||{}).befar | numFormat(2,'-')}}{{prodData.unit}}</span></div>
        </div>
        <div slot="reference" style="cursor: pointer;" @click="clickFun">{{prodData?prodData.productName:name}}</div>
      </el-popover>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ProductItem',
  props:{
    name:{
      type:String,
      default:''
    },
    prodData:{
      type:Object,
      default:()=>{return null}
    },
    position:{
      type:Object,
      default:()=>{return {top:0,left:0}}
    }
  },
  data() {
    return {

    }
  },
  computed:{
    arrow(){
      return (v)=>{
        return v>0?"↑":v<0?'↓':''
      }
    },
    textColor(){
      return (v)=>{
        return v>0?"color-red":v<0?'color-green':'color-blue'
      }
    },
    itemColor(){
      return (v)=>{
        return v>0?"red":v<0?'green':'blue'
      }
    },
  },

  methods:{
    clickFun(){
      this.$emit('click',this.prodData)
    }
  },
  created(){
   
  },
  mounted(){
    
  },
  destroyed(){
   
  },
  watch: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  @import './productGraph.scss';
</style>
